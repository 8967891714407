<template>
  <div class="fail">
    <div class="tp">
      <img src="../../assets/sbtp.png" alt="">
    </div>
    <p>支付失败</p>
     <div class="btn btns">
      重新支付
    </div>
    <div class="btn" @click='curriculum'>
      查看课程
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id:'',
      url:''
    };
  },
  mounted(){
    let currentQuery = this.$route.query;
     this.id = currentQuery.id;
    this.url = currentQuery.url;
    this.$nextTick(() => {

    });
  },
  methods:{
    curriculum() {
      this.$router.push({ path:'/'+this.url+'?id='+this.id})
    }
  }
};
</script>
<style   lang="less" scoped>
.tp {
  width: 3.83rem;
  height: 4.05rem;
  margin-top:1.48rem;
  margin-left:3.33rem;

  img {
    width: 100%;
    height: 100%;
  }
}
p {
  width: 100%;
  text-align: center;
  font-size: .4rem;
  margin:.63rem 0 .91rem 0;
  color: #2B2B2B;
  font-weight: 600;
}
div .btns,.btn {
    background: url(../../assets/cxzf.png);
     background-size: 100% 100% ;
}
.btn {
  width: 4.24rem;
  height: 1.76rem;
  background: url(../../assets/cgbtn.png);
  background-size: 100% 100% ;
  margin-left: 2.92rem;
  color: #fff;
  font-size: .48rem;
  line-height: 1.76rem;
  text-align: center;
}

</style>